import React, { useEffect, useState } from "react";
import Step1 from "../../components/Step1";
import Step2 from "../../components/Step2";
import Step3 from "../../components/Step3";
import Step4 from "../../components/Step4";
import Step5 from "../../components/Step5";
import Step6 from "../../components/Step6";
import Step7 from "../../components/Step7";
import { createUser, createUserInBusiness } from "../../graphql/mutation";
import "./styles.css";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

function Registration() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [business, setBusiness] = useState({ id: 0, name: "-" });
  const [wpp, setWpp] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [wppIndicado, setWppIndicado] = useState("");
  const notyf = new Notyf();
  const urlAPISorteio = "https://api-hmg.biud.com.br/api/";

  // Verifica se o usuário já está cadastrado na XP RD
  const submit = async () => {
    const returnVerify = await verifyCad();
    if (!returnVerify) return false;
    cadSorteio();
    await cadUserPassword();
    cadUserOnBusiness();
    validarIndicacao();
    sendFistMsgWpp();
    return true;
  };
  

  // useEffect(() => {
  //   excluirDadosDoBanco()
  // }, [])
  // gera password aleatório
  // function gerarPassword() {
  //   return Math.random().toString(36).slice(-10);
  // }

  // Cadastrando usuário na plataforma BIUD
  const cadUserPassword = async () => {
    try {
      const password = "Rd@summit"
      const res = await createUser(name, wpp, wpp, password);
    } catch (err) {
      console.log(err);
    }
  };

  // Cadastrar o usuário no business que ele escolheu
  const cadUserOnBusiness = async () => {
    try {
      const res = await createUserInBusiness(name, wpp, business.id, "OWNER");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  //Zerar banco temporario
  const excluirDadosDoBanco = async () => {
    try {
      const res = await axios({
        method: "get",
        url: urlAPISorteio + "visitant",
      });

      res.data.map(async (item) => {
        await axios({
          method: "delete",
          url: urlAPISorteio + "visitant/" + item.id,
        })
      })
    } catch(err) {
      console.log(err)
    }
  }

  //Verifica se está cadastrado no RD summit
  const verifyCad = async () => {
    try {
      const res = await axios({
        method: "get",
        url: urlAPISorteio + "visitant?phone=" + wpp,
      });

      if (res.data.length > 0) {
        notyf.error({
          message: "Número já cadastrado",
          duration: 5000,
          background: "white",
          position: {
            x: "left",
            y: "bottom",
          },
          className: "notyf-biud",
        });
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Verifica se o usuário foi indicado
  const validarIndicacao = async () => {
    try {
      const res = await axios({
        method: "get",
        url: urlAPISorteio + "visitant?phone_indicado=" + wpp,
      });

    
      if (res.data.length > 0) {
        console.log(res.data[0].id);
        await axios({
          method: "put",
          url: urlAPISorteio + "visitant/" + res.data[0].id,
          data: {
            name: res.data[0].name,
            phone: res.data[0].phone,
            valid: true,
            phone_indicado: res.data[0].phone_indicado,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Envia msg pro indicado
  const sendMsgIndicado = async () => {
    axios({
      method: "post",
      url: "https://graph.facebook.com/v15.0/111785035036702/messages",
      headers: {
        Authorization:
          "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
      },
      data: {
        messaging_product: "whatsapp",
        to: "55" + wppIndicado,
        type: "template",
        template: {
          name: "sorteio_indicacao",
          language: {
            code: "pt_BR",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: name,
                },
              ],
            },
          ],
        },
      },
    });
  };

  // Cadastra o usuário no sorteio
  const cadSorteio = async () => {
    try {
      const res = await axios({
        method: "post",
        url: urlAPISorteio + "visitant",
        data: {
          name: name,
          phone: wpp,
          valid: true,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const sendSecondMsgWpp = async () => {
    await axios({
      method: "post",
      url: "https://graph.facebook.com/v15.0/111785035036702/messages",
      headers: {
        Authorization:
          "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
      },
      data: {
        messaging_product: "whatsapp",
        to: "55" + wpp,
        type: "template",
        template: {
          name: "mia_apresentacao_2",
          language: {
            code: "pt_BR",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: business.name,
                },
              ],
            },
          ],
        },
      },
    });
    setTimeout(() => {
      sendThirdMsgWpp()
    }, 3000)
  }

  const sendThirdMsgWpp = async () => {
    await axios({
      method: "post",
      url: "https://graph.facebook.com/v15.0/111785035036702/messages",
      headers: {
        Authorization:
          "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
      },
      data: {
        messaging_product: "whatsapp",
        to: "55" + wpp,
        type: "template",
        template: {
          name: "mia_apresentacao_3",
          language: {
            code: "pt_BR",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: business.name,
                },
              ],
            },
          ],
        },
      },
    });
    setTimeout(() => {
      sendFourMsgWpp()
    }, 3000)
  }

  const sendFourMsgWpp = async () => {
    await axios({
      method: "post",
      url: "https://graph.facebook.com/v15.0/111785035036702/messages",
      headers: {
        Authorization:
          "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
      },
      data: {
        messaging_product: "whatsapp",
        to: "55" + wpp,
        type: "template",
        template: {
          name: "mia_apresentacao_4",
          language: {
            code: "pt_BR",
          }
        },
      },
    });
    setTimeout(() => {
      sendFiveMsgWpp()
    }, 3000)
  }

    const sendFiveMsgWpp = async () => {
      await axios({
        method: "post",
        url: "https://graph.facebook.com/v15.0/111785035036702/messages",
        headers: {
          Authorization:
            "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
        },
        data: {
          messaging_product: "whatsapp",
          to: "55" + wpp,
          type: "template",
          template: {
            name: "mia_apresentacao_5",
            language: {
              code: "pt_BR",
            },
            components: [
              {
                type: "body",
                parameters: [
                  {
                    type: "text",
                    text: wpp,
                  },
                  {
                    type: "text",
                    text: "Rd@summit"
                  }
                ],
              },
            ],
          },
        },
      });
    }

  // Envio da primeira mensagem pelo wpp
  const sendFistMsgWpp = async () => {
    await axios({
      method: "post",
      url: "https://graph.facebook.com/v15.0/111785035036702/messages",
      headers: {
        Authorization:
          "Bearer EAAQIBk9Sj8ABABs0Sy5tX2ZAAeENE1bjrKGmJmYMrnK4eSXJZBZAKOnIVA2h9DpdYfexzgNZA8yJFd1oddFh8fmp14bGpFww3wmOLvFf3k9CfDOLBeQrslEAJ5XpEmUZCtOVk9eAFbTFRevSmnbDbGR8ZBxctIigYb6jxOMaBa81fYZBN1oBKOD",
      },
      data: {
        messaging_product: "whatsapp",
        to: "55" + wpp,
        type: "template",
        template: {
          name: "mia_apresentacao_1",
          language: {
            code: "pt_BR",
          },
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: name,
                }
              ],
            },
          ],
        },
      },
    });
    setTimeout(() => {
      sendSecondMsgWpp();
    }, 3000)
  };

  const restart = () => {
    setStep(1);
    setName("");
    setWpp("");
    setBusiness({ id: 0, name: "-" });
    setWppIndicado("");
  };

  const verificarIndicacao = async () => {
    const p1 = axios({
      method: "get",
      url: urlAPISorteio + "visitant?phone_indicado=" + wppIndicado,
    });

    const p2 = axios({
      method: "get",
      url: urlAPISorteio + "visitant?phone=" + wppIndicado,
    });

    try {
      const [res1, res2] = await Promise.all([p1, p2]);
      const res = [...res1.data, ...res2.data];
      if (res.length > 0) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const submitInd = async () => {
    setLoading(true);

    try {
      const indicacaoValida = await verificarIndicacao();
      if (!indicacaoValida) {
        notyf.error({
          message: "Pessoa já indicada",
          duration: 5000,
          background: "white",
          position: {
            x: "left",
            y: "bottom",
          },
          className: "notyf-biud",
        });
        // setWppIndicado("");
        throw new Error("Número já indicado");
      }

      const res = await axios({
        method: "post",
        url: urlAPISorteio + "visitant",
        data: {
          name: name,
          phone: wpp,
          phone_indicado: wppIndicado,
          valid: false,
        },
      });
      sendMsgIndicado()
      setStep(7);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const steps = {
    1: <Step1 setStep={setStep} />,
    2: (
      <Step2
        setStep={setStep}
        name={name}
        setName={setName}
        restart={restart}
      />
    ),
    3: (
      <Step3
        setStep={setStep}
        name={name}
        setBusiness={setBusiness}
        restart={restart}
      />
    ),
    4: (
      <Step4
        setStep={setStep}
        business={business}
        wpp={wpp}
        setWpp={setWpp}
        submit={submit}
        restart={restart}
        loading={loading}
        setLoading={setLoading}
      />
    ),
    5: (
      <Step5
        setStep={setStep}
        name={name}
        business={business}
        restart={restart}
        setWpp={setWpp}
      />
    ),
    6: (
      <Step6
        wpp={wppIndicado}
        setWpp={setWppIndicado}
        setStep={setStep}
        restart={restart}
        submitInd={submitInd}
        loading={loading}
      />
    ),
    7: (
      <Step7
        name={name}
        restart={restart}
        setStep={setStep}
        setWpp={setWppIndicado}
      />
    ),
  };

  return <main id="main-registration">{steps[step]}</main>;
}

export default Registration;
