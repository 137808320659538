import React, { useState } from 'react';
import { Fade } from '@mui/material';
import iphone13 from '../images/confeti1.gif';
import axios from 'axios';
import { getUsers } from '../graphql/mutation';

function Sorteio() {
    const [name, setName] = useState("Leonardo");
    const [ticket, setTicket] = useState(6576);
    const [phone, setPhone] = useState("");
    const [step, setStep] = useState(1);
    const urlAPISorteio = "https://api-generator.retool.com/fE3Z8E/";

    const next = async () => {
        setStep(2)

        try {
            
            const res = await getUsers()
            
            setStep(3)
            var numeros = res.data.Users.data;
            function buscarIdsMaiorQueInicio (value) {
                if (value.id < 1525 == 0) 
                return value;
            }
            var numerosPares = await numeros.filter(buscarIdsMaiorQueInicio);
            console.log(numerosPares);

            const sorteado = await Math.floor(Math.random() * numerosPares.length)
            console.log(numerosPares[sorteado])
            //   const randomNumber = await Math.random(); 

            //   const winner = res.data[randomNumber]


              setName(numerosPares[sorteado].name)
              setTicket(numerosPares[sorteado].id)
              setPhone('('+numerosPares[sorteado].phone.substr(0, 2)+')XXXXX'+ numerosPares[sorteado].phone.substr(7))
        } catch(err) {
            console.log(err)
        }
    }

  return (
    <Fade in>
      <section>
        <div class="container-sorteio">
            {step === 1 && (
                <div class="child-sorteio">
                <button
                    type="button"
                    className="iphone-btn"
                    onClick={next}
                >
                    Sortear iPhone 14 Pro
                </button>
                </div>
            )}

            {step === 2 && (
                <div class="child-sorteio">
                    <img src={iphone13} alt="iphone 13 pro max" />
                </div>
            )}

            {step === 3 && (
                <div class="child-sorteio">
                    <p className="title title-4 transition"> 
                        <h2>Dados do vencedor</h2>
                        <br />
                        Ticket #{ticket}
                        <br />
                        Nome: {name}
                        <br />
                        Celular: {phone}
                    </p>
                </div>
            )}
            </div>
          <nav>
          </nav>
      </section>
    </Fade>
  );
}

export default Sorteio;

