import React from "react";
import ReactLoading from "react-loading";

export default function Loading() {
  return (
    <ReactLoading
      type="spinningBubbles"
      color="#d81656"
      height="36px"
      width="36px"
    />
  );
}
