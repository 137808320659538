import React from "react";
import PropTypes from "prop-types";
import {Fade} from "@mui/material";
import homeIcon from "../images/home-alt.svg";
import iphone14 from "../images/iphone-14.png";

function Step5({setStep, name, business, restart, setWpp}) {
    const next = () => {
        setStep(6);
    };

    return (
        <Fade in>
            <section>
                <div className="banner">
                    <nav className="nav-container">
                        <button type="button" className="circle-btn" onClick={restart}>
                            <img src={homeIcon} alt="home icon"/>
                        </button>

                        <p className="title title-5">
                            Pronto, {name}
                            !
                            <br/>A <strong>experiência Mia</strong> que vai potencializar os
                            resultados da sua {business.name} vai começar no seu celular.
                        </p>
                    </nav>
                </div>

                <div className="rectangle">
                    <div className="btns-container">
                        <p
                            className="label"
                            style={{
                                alignSelf: "flex-end",
                                fontSize: 20,
                                marginBottom: 8,
                                marginRight: 75
                            }}
                        >
                            Indique alguém que está no RD Summit e aumente suas chances de
                            ganhar.
                        </p>
                        <button type="button" className="iphone-btn" onClick={next}>
                            <img src={iphone14} alt="iphone 14 pro" className="iphone14"/>
                            Indicar agora
                        </button>

                        <button
                            type="button"
                            className="final-btn"
                            onClick={restart}
                            style={{marginTop: "32px"}}
                        >
                            Finalizar
                        </button>
                    </div>
                </div>
            </section>
        </Fade>
    );
}

Step5.propTypes = {
    setStep: PropTypes.func.isRequired,
    setWpp: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    business: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    restart: PropTypes.func.isRequired,
};

export default Step5;

