import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@mui/material';

function Step1({ setStep }) {
  return (
    <Fade in>
      <section>
        <div className="banner">
          <p className="title title-1">
            Olá, eu sou a Mia!
            <br />
            Vou te mostrar o meu jeito de fazer marketing direcionado por dados.
          </p>
        </div>

        <div className="rectangle">
          <button
            type="button"
            onClick={() => setStep(2)}
            className="outline-btn"
          >
            Vamos nessa, Mia!
          </button>
        </div>
      </section>
    </Fade>
  );
}

Step1.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default Step1;
