import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Raffle from './pages/Raffle';
import Registration from './pages/Registration';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Registration />}
      />

      <Route
        path="/sorteio"
        element={<Raffle />}
      />

      <Route
        path="*"
        element={(
          <section style={{ padding: '1rem' }}>
            <p>There is nothing here!</p>
          </section>
          )}
      />
    </Routes>
  );
}

export default App;
