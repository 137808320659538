import { client, gql } from "./config";

export async function createUserInBusiness(
    name,
    email,
    businessId,
    type
  ) {
    const RegisterUserMutation = gql`
      mutation (
          $name: String!
          $email: String!
          $businessId: Int!
          $type: BusinessUserType
      ){
      RegisterBusinessUser(
          name: $name,
          email: $email,
          businessId: $businessId,
          type: $type
          )
      }`;
  
    return await
      client
        .mutate({
          mutation: RegisterUserMutation,
          variables: {
            name,
            email,
            businessId,
            type
          },
          fetchPolicy: 'no-cache'
        });
  }

  export async function createUser(
    name,
    email,
    phone,
    password,
  ) {
    const RegisterUserMutation = gql`
      mutation (
          $name: String!
          $email: String!
          $phone: String!
          $password: String!
      ){
      RegisterUser(
          name: $name,
          email: $email,
          phone: $phone,
          password: $password
          ) {
            id
          }
      }`;
  
    return await
      client
        .mutate({
          mutation: RegisterUserMutation,
          variables: {
            name,
            email,
            phone,
            password
          },
          fetchPolicy: 'no-cache'
        });
  }

  export async function getUsers(){
    const getUsers = gql`
    query User {
      Users{
        data{
          id,
          phone, 
          name,
        }
      }
    }`;

  return await client.query({
    query: getUsers,
    fetchPolicy: "no-cache",
  })

}