import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Keyboard from 'react-simple-keyboard';
import { Fade } from '@mui/material';
import lockIcon from '../images/lock.svg';
import arrowLeft from '../images/arrow-left.svg';

function Step2({
  setStep,
  name,
  setName,
  restart,
}) {
  const [layoutState, setLayoutState] = useState(name === '' ? 'shift' : 'default');
  const [disableBtn, setDisableBtn] = useState(name.length <= 1);

  const handleInputChange = (input) => {
    const trimmedInput = input.trim();
    setName(trimmedInput);

    if (trimmedInput.length === 0) setLayoutState('shift');
    if (trimmedInput.length > 1) setDisableBtn(false);
    else setDisableBtn(true);
  };

  const layout = ['lock', 'shift'].includes(layoutState) ? 'shift' : 'default';

  const onKeyPress = (button) => {
    if (button === '{lock}') setLayoutState(layout === 'default' ? 'lock' : 'default');
    else if (button === '{shift}') setLayoutState(layout === 'default' ? 'shift' : 'default');
    else if (button === '{space}' || (name.length === 0 && button === '{bksp}')) setLayoutState('shift');
    else if (layoutState !== 'lock') setLayoutState('default');
  };

  const brLayout = {
    default: [
      "' q w e r t y u i o p {bksp}",
      '{lock} a s d f g h j k l \u00E7',
      '{shift} z x c v b n m - {shift}',
      '{space}',
    ],
    shift: [
      "' Q W E R T Y U I O P {bksp}",
      '{lock} A S D F G H J K L \u00C7',
      '{shift} Z X C V B N M - {shift}',
      '{space}',
    ],
  };

  const options = {
    onChange: (input) => handleInputChange(input),
    onKeyPress: (button) => onKeyPress(button),
    layout: brLayout,
    layoutName: layout,
    maxLength: 50,
    onInit: (keyboard) => keyboard.setInput(name),
    buttonTheme: [
      {
        class: 'hg-button-bksp-normal hg-standardBtn',
        buttons: '{bksp}',
      },
    ],
    display: {
      '{bksp}': ' ',
      '{lock}': 'CapsLock',
      '{shift}': 'Shift',
      '{space}': ' ',
    },
  };

  // eslint-disable-next-line no-unused-vars
  const keyboard = new Keyboard(options);

  // acento grave \u00B4

  return (
    <Fade in>
      <section>
        <div className="banner">
          <nav className="nav-container">
            <button
              type="button"
              className="circle-btn"
              onClick={restart}
            >
              <img src={arrowLeft} alt="arrow left icon" />
            </button>
          </nav>

          <p
            className="title title-2"
          >
            Para começar,
            <br />
            digite o seu nome.
          </p>

          <input
            className="input name-input"
            type="text"
            value={name}
            readOnly
          />

          <button
            type="button"
            className={`continue-btn continue-btn-1 ${disableBtn ? 'disabled-btn' : 'enabled-btn'}`}
            disabled={disableBtn}
            onClick={() => setStep(3)}
          >
            <span>Continuar</span>
          </button>

          <div className="obs-container obs-container-name">
            <img src={lockIcon} alt="lock" className="icon" />
            <span>Não se preocupe, não compartilharemos esse dado com ninguém.</span>
          </div>
        </div>

        <div className="rectangle">
          <div className="react-simple-keyboard" />
        </div>
      </section>
    </Fade>
  );
}

Step2.propTypes = {
  setStep: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default Step2;
