import React from "react";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";
import homeIcon from "../images/home-alt.svg";
import iphone14 from "../images/iphone-14.png";

function Step7({ name, restart, setStep, setWpp }) {
  const anotherInd = () => {
    setWpp("");
    setStep(6);
  };

  return (
    <Fade in>
      <section>
        <div className="banner">
          <nav className="nav-container">
            <button type="button" className="circle-btn" onClick={restart}>
              <img src={homeIcon} alt="home icon" />
            </button>
          </nav>
          <p className="title title-7">
            Recebi sua indicação, {name}
            !
            <br />
            Você pode indicar mais amigos ou finalizar o formulário.
          </p>
        </div>
        <div className="rectangle">
          <div className="btns-container">
            <button type="button" className="iphone-btn" onClick={anotherInd}>
              <div className="iphone-chip" style={{ top: "128px" }}>
                iPhone 14 Pro
              </div>
              <img
                src={iphone14}
                alt="iphone 14 pro"
                className="iphone14"
                style={{ top: "59px" }}
              />
              Indicar mais
            </button>

            <button
              type="button"
              onClick={restart}
              className="final-btn"
              style={{ marginTop: "32px" }}
            >
              Finalizar
            </button>
          </div>
        </div>
      </section>
    </Fade>
  );
}

Step7.propTypes = {
  name: PropTypes.string.isRequired,
  restart: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setWpp: PropTypes.func.isRequired,
};

export default Step7;
