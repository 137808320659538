import React, { useState } from 'react';
import { createUserInBusiness } from '../../graphql/mutation';
// import './styles.css';
import axios from 'axios';
import Sorteio from '../../components/Sorteio';

function Raffle() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [business, setBusiness] = useState('');
  const [wpp, setWpp] = useState('');

  const submit = async () => {
    // eslint-disable-next-line no-console
    // console.log('Pessoa registrada:', { name, email: wpp, businessbusiness });
    // sendFistMsgWpp()
    // try {
    //   const res = await createUserInBusiness(name, wpp, business.id, "OWNER")
    //   console.log(res);
    // } catch(err) {
    //   console.log(err)
    // }
  };

  return (
    <main id="main-registration" style={{alignItems: 'center'}}>
      <Sorteio />
    </main>
  );
}

export default Raffle;
