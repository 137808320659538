import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@mui/material';
import Keyboard from 'react-simple-keyboard';
import arrowLeft from '../images/arrow-left.svg';
import homeIcon from '../images/home-alt.svg';
import lockIcon from '../images/lock.svg';
import usersIcon from '../images/users-alt.svg';
import Loading from './Loading';

function Step6({
  wpp, setWpp, setStep, restart, submitInd, loading
}) {
  const keyboard = useRef();
  const [disableBtn, setDisableBtn] = useState(wpp.length !== 11);

  const formatWpp = (number) => {
    const ddd = number.slice(0, 2) || 'xx';
    const firstPart = number.slice(2, 7) || 'xxxxx';
    const secondPart = number.slice(7) || 'xxxx';
    const formattedNumber = `(${ddd}) ${firstPart}-${secondPart}`;
    return formattedNumber;
  };

  const handleInputChange = (input) => {
    setWpp(input);

    if (input.length === 11) setDisableBtn(false);
    else setDisableBtn(true);
  };

  const numLayout = {
    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '0 {bksp}',
    ],
  };

  return (
    <Fade in>
      <section>
        <div className="banner">
          <nav className="nav-container">
            <button
              type="button"
              className="circle-btn"
              onClick={() => setStep(5)}
            >
              <img src={arrowLeft} alt="arrow left icon" />
            </button>

            <button
              type="button"
              className="circle-btn"
              onClick={restart}
            >
              <img src={homeIcon} alt="home icon" />
            </button>
          </nav>

          <p className="title title-6">
            Indique o WhatsApp de alguém que esteja
            no RD Summit e aumente suas chances
            de ganhar um iPhone 14 Pro.
          </p>

          <p className="label label-wpp-friend">
            Digite o WhatsApp 
          </p>

          <input
            type="text"
            value={formatWpp(wpp)}
            className="input wpp-input wpp-input-2"
            readOnly
          />

          <button
            type="button"
            disabled={disableBtn}
            className={`continue-btn continue-btn-3 ${disableBtn || loading ? 'disabled-btn' : 'enabled-btn'}`}
            onClick={submitInd}
          >
            {loading ? <Loading /> : "Continuar"}
          </button>

          <div className="obs-container obs-container-friend">
            <img src={usersIcon} alt="lock" className="icon" />
            <span>
              Sua indicação precisa visitar nosso
              stand e participar da experiência Mia.
            </span>
          </div> 
          
          <div className="obs-container obs-container-wpp-friend">
            <img src={lockIcon} alt="lock" className="icon " />
            <span>Não se preocupe, não compartilharemos esse dado com ninguém.</span>
          </div>      
        </div>
        <div className="rectangle">
          <Keyboard
            keyboardRef={(r) => { keyboard.current = r; }}
            layoutName="default"
            onChange={(input) => handleInputChange(input)}
            layout={numLayout}
            maxLength={11}
            // disableButtonHold
            theme="hg-theme-num"
            buttonTheme={[
              {
                class: 'hg-button-bksp hg-standardBtn',
                buttons: '{bksp}',
              },
              {
                class: 'hg-button-zero',
                buttons: '0',
              },
            ]}
            display={{
              '{bksp}': ' ',
            }}
          />
        </div>
      </section>
    </Fade>
  );
}

Step6.propTypes = {
  wpp: PropTypes.string.isRequired,
  setWpp: PropTypes.func.isRequired,
  submitInd: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired,
};

export default Step6;
