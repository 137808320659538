import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@mui/material';
import hamburger from '../images/hamburger.svg';
import pill from '../images/pill.svg';
import tshirt from '../images/clothes.svg';
import arrowLeft from '../images/arrow-left.svg';
import homeIcon from '../images/home-alt.svg';

function Step3({
  setStep, setBusiness, name, restart,
}) {
  const handleClick = (business) => {
    setStep(4);
    setBusiness(business);
  };

  return (
    <Fade in>
      <section>
        <div
          className="banner"
        >
          <nav className="nav-container">
            <button
              type="button"
              className="circle-btn"
              onClick={() => setStep(2)}
            >
              <img src={arrowLeft} alt="arrow left icon" />
            </button>

            <button
              type="button"
              className="circle-btn"
              onClick={restart}
            >
              <img src={homeIcon} alt="home icon" />
            </button>
          </nav>
          <p className="title title-3">
            Ótimo,
            {' '}
            {name}
            !
            <br />
            Me conta em qual segmento você gostaria de simular a sua experiência. 
          </p>
        </div>

        <div className="rectangle">
          <div className="business-options-container">
            <button
              type="button"
              className="round-button"
              onClick={() => handleClick({ id: 1469, name: 'hamburgueria' })}
            >
              <img src={hamburger} alt="hamburger emoji" width="15px" />
              <span>Hamburgueria</span>
            </button>

            <button
              type="button"
              className="round-button"
              onClick={() => handleClick({ id: 2186, name: 'drogaria' })}
            >
              <img src={pill} alt="pill emoji" width="15px" />
              <span>Drogaria</span>
            </button>

            <button
              type="button"
              className="round-button"
              onClick={() => handleClick({ id: 2094, name: 'loja de roupas' })}
            >
              <img src={tshirt} alt="t-shirt emoji" width="15px" />
              <span>Loja de Roupas</span>
            </button>
          </div>
        </div>

      </section>
    </Fade>
  );
}

Step3.propTypes = {
  name: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired,
  setBusiness: PropTypes.func.isRequired,
};

export default Step3;
