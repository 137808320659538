import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";
import Keyboard from "react-simple-keyboard";
import arrowLeft from "../images/arrow-left.svg";
import homeIcon from "../images/home-alt.svg";
import lockIcon from "../images/lock.svg";
import Loading from "./Loading";

function Step4({
  setStep,
  business,
  wpp,
  setWpp,
  submit,
  restart,
  loading,
  setLoading,
}) {
  const [disableBtn, setDisableBtn] = useState(wpp.length !== 11);
  const keyboard = useRef();

  const finish = async () => {
    setLoading(true);

    try {
      const submitted = await submit();
      if (submitted) setStep(5);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (input) => {
    setWpp(input);

    if (input.length === 11) setDisableBtn(false);
    else setDisableBtn(true);
  };

  const formatWpp = (number) => {
    const ddd = number.slice(0, 2) || "xx";
    const firstPart = number.slice(2, 7) || "xxxxx";
    const secondPart = number.slice(7) || "xxxx";
    const formattedNumber = `(${ddd}) ${firstPart}-${secondPart}`;
    return formattedNumber;
  };

  const numLayout = {
    default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
  };

  const hambText = (
    <div className="title title-4a">
      <p>
        O tempero secreto para o sucesso do marketing da sua hamburgueria você
        vai{" "}
      </p>
      <p>experimentar no seu WhatsApp.</p>
    </div>
  );

  const drogText = (
    <div className="title title-4b">
      <p>
        A receita para o sucesso da sua drogaria você vai receber no seu
        WhatsApp.
      </p>
    </div>
  );

  const lojaDeRoupas = (
    <div className="title title-4c">
      <p>
        A estratégia sob medida para o sucesso da sua loja de roupas você vai
        experimentar no
      </p>
      <p>seu WhatsApp.</p>
    </div>
  );

  const text = {
    hamburgueria: hambText,
    drogaria: drogText,
    "loja de roupas": lojaDeRoupas,
  };

  return (
    <Fade in>
      <section>
        <div className="banner">
          <nav className="nav-container">
            <button
              type="button"
              className="circle-btn"
              onClick={() => {
                setWpp("");
                setStep(3);
              }}
            >
              <img src={arrowLeft} alt="arrow left icon" />
            </button>

            <button type="button" className="circle-btn" onClick={restart}>
              <img src={homeIcon} alt="home icon" />
            </button>
          </nav>

          {text[business.name]}

          <p className="label label-tel">Digite seu número</p>
          <input
            type="text"
            value={formatWpp(wpp)}
            className="input wpp-input wpp-input-1"
            readOnly
          />

          <button
            type="button"
            disabled={disableBtn}
            className={`continue-btn continue-btn-2 ${
              disableBtn || loading ? "disabled-btn" : "enabled-btn"
            }`}
            onClick={finish}
          >
            {loading ? <Loading /> : "Continuar"}
          </button>

          <div className="obs-container obs-container-wpp">
            <img src={lockIcon} alt="lock" className="icon" />
            <span>
              Não se preocupe, não compartilharemos esse dado com ninguém.
            </span>
          </div>
        </div>

        <div className="rectangle">
          <Keyboard
            keyboardRef={(r) => {
              keyboard.current = r;
            }}
            layoutName="default"
            onChange={(input) => handleInputChange(input)}
            layout={numLayout}
            maxLength={11}
            // disableButtonHold
            theme="hg-theme-num"
            buttonTheme={[
              {
                class: "hg-button-bksp hg-standardBtn",
                buttons: "{bksp}",
              },
              {
                class: "hg-button-zero",
                buttons: "0",
              },
            ]}
            display={{
              "{bksp}": " ",
            }}
          />
        </div>
      </section>
    </Fade>
  );
}

Step4.propTypes = {
  setStep: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  submit: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired,
  wpp: PropTypes.string.isRequired,
  setWpp: PropTypes.func.isRequired,
};

export default Step4;
